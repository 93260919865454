<div class="navbar flex justify-between bg-base-100">
    <div>
        <a [routerLink]="['/']" class="btn btn-ghost text-xl normal-case">
            <img
                class="w-[175px]"
                [src]="
                    themeService.isDark()
                        ? '/assets/images/cerp-logo-addon-dark.png'
                        : '/assets/images/cerp-logo-addon-light.png'
                "
            />
        </a>
    </div>
    @if (releaseNoteListStateService.select('releaseNotes') | async; as releaseNotes) {
        <div class="hidden md:flex">
            <app-release-note-carousel
                [releaseNotes]="releaseNotes"
                class="text-center"
            ></app-release-note-carousel>
        </div>
    }
    <div class="">
        <ul class="menu menu-horizontal flex px-1">
            <li>
                <a class [routerLink]="authRoutes.signIn.path"> Sign In </a>
            </li>
            <li>
                <a class [routerLink]="authRoutes.signUp.path"> Sign Up </a>
            </li>
        </ul>
    </div>
</div>
